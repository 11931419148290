.title-login{
    font-family: 'Helvethaica_Bold';
    font-size: 26px;
}

.form-input{
    font-size: 20px;
}

.btn-submit{
    font-size: 20px;
    font-family: 'Helvethaica_Medium';
}